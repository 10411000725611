import { EmployeeId } from './../models/learning-plan-id';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  id:EmployeeId;
  ROUTE:string='';
  url:String=''

  languages:any[]=[
    { "id": "English", "name": "English" },
    { "id": "Spanish", "name": "Spanish" },
    // { "id": "Portuguese", "name": "Portuguese" },
    // { "id": "Italian", "name": "Italian" },
    // { "id": "French", "name": "French" },
    // { "id": "Chinese", "name": "Chinese" },
    // { "id": "Dutch", "name": "Dutch" },
    // { "id": "Japanese", "name": "Japanese" },
    // { "id": "Canadian", "name": "Canadian" },
    // { "id": "French-Canadian", "name": "French-Canadian" }
  ]
  


  constructor(private router:Router) { 

    

  }

  

  getLanguage()
  {
    return this.languages
  }

  saveId(id:EmployeeId){
    this.id=id
    
  }


getId()
{
  return this.id
}

  getRoute()
  {
    this.url=this.router.routerState.snapshot.url

    if(this.url.includes('pip'))
      {
        this.ROUTE='/performancerc3/pip-manager';
      }
      else if(this.url.includes('idp'))
      {
        this.ROUTE='/talentManagmentrc/idp-manager'
      }
 
    
      return this.ROUTE
  }


  getRouteLearningPlan()
  {
    this.url=this.router.routerState.snapshot.url
    console.log(this.url);
    

    if(this.url.includes('talentManagmentrc'))
      {
        this.ROUTE='/talentManagmentrc/learningPlan/lp';
      }
      else if(this.url.includes('performancerc3/reviewMain'))
      {
        this.ROUTE='/performancerc3/reviewMain/lp'
      }
 
    
      return this.ROUTE
  }




  routeType(url)
  {
    if(url.includes('pip'))
    {
      return 'PIP';
    }
    if(url.includes('idp'))
    {
      return 'IDP';
    }


  }


}
