import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TrainerydataService {

  constructor() { }


  topMenuAccess(submenu:string)
  {     
    let access=localStorage.getItem('access');
    let topMenu=JSON.parse(access)
    return topMenu.find(item => item.name === 'Review Cloud')?.side_menu.find(subItem => subItem.name === submenu);
  }

  subMenuAccess(topmenu:string,submenu:string)
  {
    let access=localStorage.getItem('access');
    let topMenu=JSON.parse(access)
    return topMenu.find(item => item.name === 'Review Cloud')?.side_menu.find(topItem => topItem.name === topmenu)?.top_menu.find(subItem=>subItem.name===submenu);

  }


 setTraineryApplicationData(resData:any)
 {

  let userData={
    user_id: resData.user_id,
    email:resData.email,
    role: resData.roles[0].id,
    full_name:resData.full_name,
    profile_pic:resData.profile_pic,
    first_name:resData.first_name,
    roleName:resData.roles[0].key_name,
    impersonate_acc:resData.impersonate_acc,
    fiscalEndMonth:resData.organization.fiscal_end
   }   

  localStorage.setItem('user_data',JSON.stringify(userData))
  localStorage.setItem('applications', JSON.stringify(resData.applications));
  localStorage.setItem('sidebar',JSON.stringify(resData.left_menu));
  localStorage.setItem('access',JSON.stringify(resData.access))
  localStorage.setItem('role', resData.roles[0].key_name);

 } 

 getTraineryApplicationData(name:string)
 {

  let data = localStorage.getItem(name);
  let parsedData= JSON.parse(data);
  
return parsedData;

 }


 public getCookie(name: string) {
  const ca: Array<string> = decodeURIComponent(document.cookie).split(';');
  const caLen: number = ca.length;
  const cookieName = `${name}=`;
  let c: string;

  for (let i  = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) === 0) {
          return c.substring(cookieName.length, c.length);
      }
  }
  return '';
}

public setCookie(name: string,value:any,days: number = 0) {
  var expires = "";
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + ";domain=.trainery.one;Secure;path=/;";
}

public setPushpin(pinval: boolean) {
  /* const headers = new HttpHeaders({ 'X-CSRFToken': this.csrftoken});
  return this.http.post<any>( environment.trainery_api_url + 'api/setpin/', { pinned: pinval }, {
      headers,
      withCredentials: true
  }); */
  this.setCookie('pinned', pinval?'True': 'False');
}


public setDropdown(dropdown: boolean) {
  this.setCookie('dropdownEnabled', dropdown?'True': 'False');
}


}
